// Packages:
import React, { useEffect } from 'react'
import { navigate } from 'gatsby'


// Constants:
import { ROUTE } from '../constants'


// Functions:
const Resume = () => {
  // Effects:
  useEffect(() => {
    if (window) {
      const link = document.createElement('a')
      link.href = 'https://docs.google.com/document/d/1VBLW09Ykg6k5iXgfmPAGxMVogBSFDkCqI5HbSKeQWPM/edit?usp=sharing'
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
      navigate(ROUTE.INDEX.path)
    }
  }, [])
  

  // Return:
  return <div></div>
}


// Exports:
export default Resume
